import AsyncLoadingButton from '@/components/form/asyncLoading/asyncLoadingButton';
import LayoutAnimation, { LayoutAnimationStaggerParent } from '@/components/framerAnimations/layoutAnimation';
import StyledImage from '@/components/styledImage';
import { mutateGraphQL } from '@/data';
import { UserWrite } from '@/data/user.graphql';
import useUserInfo from '@/providers/auth/useUserInfo';
import { useStepper } from '@/providers/stepper';
import { MutationUserWriteArgs } from '@/types/schema';
import {
	AccountCircleRounded as AccountCircleRoundedIcon,
	JoinFull as JoinFullIcon,
	StorefrontRounded as StorefrontRoundedIcon,
} from '@mui/icons-material';
import {
	Box,
	Collapse,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Paper,
	Stack,
	TextField,
	Typography,
} from '@mui/material';
import { useRouter } from 'next/router';
import { useState } from 'react';

type accountType = 'business' | 'clover' | 'join' | '';

export default function WelcomeInitialStep() {
	const { setActiveStep } = useStepper();
	const router = useRouter();
	const { user } = useUserInfo();
	
	const [ invitationId, setInvitationId ] = useState( '' );
	const [ type, setType ] = useState<accountType>( '' );
	
	return (
		<Box width={{ xs: '100%', md: 700 }}>
			<LayoutAnimationStaggerParent>
				<Stack spacing={3}>
					<Stack spacing={1}>
						<LayoutAnimation hasStagger variant='slideUp'>
							<Typography variant='h1' style={{ fontSize: '45px' }}>
								Choose your account type
							</Typography>
						</LayoutAnimation>
						<LayoutAnimation hasStagger variant='slideUp'>
							<Typography style={{ fontSize: '20px' }} color='text.secondary'>
								Create your company profile or view as a client
							</Typography>
						</LayoutAnimation>
					</Stack>
					<Box sx={{ width: { xs: '100%', sm: 500 } }}>
						<Stack spacing={1}>
							<LayoutAnimation hasStagger variant='slideUp'>
								<Paper
									sx={{
										'bgcolor'      : type === 'business' ? 'alpha.primary' : 'background.default',
										'transition'   : 'all 0.3s ease-in-out',
										'flexDirection': 'column',
										'borderRadius' : 3,
										'p'            : 3,
										'&:hover'      : {
											cursor : 'pointer',
											bgcolor: 'alpha.primary',
										},
									}}
									onClick={() => setType( type === 'business' ? '' : 'business' )}>
									<Stack pb={2} direction='row' spacing={1}>
										<Box
											sx={{
												p           : 1,
												pb          : 0,
												borderRadius: 2,
												bgcolor     : 'alpha.secondary',
											}}>
											<AccountCircleRoundedIcon color='secondary'/>
										</Box>
										<Box
											sx={{
												p           : 1,
												pb          : 0,
												borderRadius: 2,
												display     : 'flex',
												width       : '100%',
												bgcolor     : 'alpha.primary',
											}}>
											<StorefrontRoundedIcon color='primary'/>
										</Box>
									
									</Stack>
									<Typography gutterBottom variant='h2' sx={{ fontSize: '20px' }}>
										I have a business Or I am a Client
									</Typography>
									<Typography style={{ fontSize: '18px' }} color='text.secondary'>
										View or Create branded Estimates, Invoices, and Orders. Pay and receive payments online.
									</Typography>
									<Collapse in={type === 'business'}>
										<Stack spacing={1} p={2}>
											<ListItemButton
												disableGutters
												sx={{
													bgcolor: 'background.default',
													p      : 3,
												}}
												onClick={() => setActiveStep( 1 )}>
												<ListItemIcon className='iconStyle'>
													<StorefrontRoundedIcon sx={{ color: 'text.primary' }}/>
												</ListItemIcon>
												<ListItemText
													primary='Business'
													primaryTypographyProps={{ variant: 'h2' }}
													secondary='Lets get started with your business profile'
												/>
											</ListItemButton>
											<ListItemButton
												disableGutters
												sx={{
													bgcolor: 'background.default',
													p      : 3,
												}}
												onClick={async () => {
													if ( !user?.clientOnly ) {
														await mutateGraphQL<MutationUserWriteArgs>( {
															mutation : UserWrite,
															variables: {
																input: {
																	clientOnly: true,
																},
															},
														} );
													}
													await router.push( '/client' );
												}}>
												<ListItemIcon className='iconStyle'>
													<AccountCircleRoundedIcon sx={{ color: 'text.primary' }}/>
												</ListItemIcon>
												<ListItemText
													primary='Client'
													primaryTypographyProps={{ variant: 'h2' }}
													secondary={`View any Invoices, Estimates and Orders that were sent to ${user?.email}`}
												/>
											</ListItemButton>
										</Stack>
									</Collapse>
								</Paper>
							</LayoutAnimation>
							<LayoutAnimation hasStagger variant='slideUp'>
								<Paper
									sx={{
										'bgcolor'      : 'background.default',
										'transition'   : 'all 0.3s ease-in-out',
										'flexDirection': 'column',
										'borderRadius' : 3,
										'p'            : 3,
										'&:hover'      : {
											cursor : 'pointer',
											bgcolor: 'alpha.success',
										},
									}}
									onClick={() => window.open( 'https://www.clover.com/appmarket/apps/9TDMF3T5AN9T2', '_blank' )}>
									<Stack
										sx={{
											display     : 'flex',
											bgcolor     : 'alpha.success',
											p           : 1,
											borderRadius: 2,
											mb          : 2,
											width       : '100%',
										}}>
										<StyledImage
											alt='square-logo'
											src='/images/clover-icon.png'
											sx={{ width: 20, height: 20 }}
										/>
									</Stack>
									<Typography gutterBottom variant='h2' sx={{ fontSize: '20px' }}>
										I have a business on Clover
									</Typography>
									<Typography style={{ fontSize: '18px' }} color='text.secondary'>
										That's fantastic! Let's quickly connect your Clover account and unleash the full potential
										of Invoiss.
									</Typography>
								</Paper>
							</LayoutAnimation>
							
							<LayoutAnimation hasStagger variant='slideUp'>
								<Paper
									sx={{
										'bgcolor'      : type === 'join' ? 'alpha.warning' : 'background.default',
										'transition'   : 'all 0.3s ease-in-out',
										'flexDirection': 'column',
										'borderRadius' : 3,
										'p'            : 3,
										'&:hover'      : {
											cursor : 'pointer',
											bgcolor: 'alpha.warning',
										},
									}}
									onClick={() => setType( type === 'join' ? '' : 'join' )}>
									<Box
										sx={{
											p           : 1,
											pb          : 0,
											borderRadius: 2,
											bgcolor     : 'alpha.warning',
											mb          : 2,
										}}>
										<JoinFullIcon color='warning'/>
									</Box>
									<Typography gutterBottom variant='h2' sx={{ fontSize: '20px' }}>
										Join a company
									</Typography>
									<Typography style={{ fontSize: '18px' }} color='text.secondary'>
										If you were invited to join a company, enter the invitation ID below.
									</Typography>
									<Collapse in={type === 'join'}>
										<Stack spacing={1} mt={1}>
											<Typography style={{ fontSize: 16 }}>
												Join a company with {user?.email}
											</Typography>
											<TextField
												fullWidth
												size='medium'
												placeholder='Enter the invitation ID you received from the merchant'
												value={invitationId}
												onChange={( e ) => setInvitationId( e.target.value )}
												onClick={( e ) => e.stopPropagation()}
											/>
											<AsyncLoadingButton
												sx={{ width: '100%' }}
												variant='contained'
												color='primary'
												type='submit'
												disabled={!invitationId}
												size='large'
												onClick={async ( e ) => {
													e.stopPropagation();
													await router.push( `${process.env.NEXT_PUBLIC_SITE_URL}/p/join?code=${invitationId}` );
												}}>
												Join Now
											</AsyncLoadingButton>
										</Stack>
									</Collapse>
								</Paper>
							</LayoutAnimation>
						</Stack>
					</Box>
				</Stack>
			</LayoutAnimationStaggerParent>
		</Box>
	);
}
