import { mutateGraphQL } from '@/data';
import { CreateCompany } from '@/data/company.graphql';
import { useCompanyId } from '@/providers/auth/useSessionStorageCompanyId';
import { useCustomerFromPartnerId } from '@/providers/auth/useSessionStoragePartnerId';
import { MutationCreateCompanyArgs } from '@/types/schema';
import { SlackNewSignUp } from '@/types/slackNewSignUp';
import axios from 'axios';
import { format } from 'date-fns';
import { useSnackbar } from 'notistack';

export default function useNewCompanyHandleSubmit() {
	const { enqueueSnackbar } = useSnackbar();
	const { setCompanyId } = useCompanyId();
	const { sessionCustomerFromPartnerId } = useCustomerFromPartnerId();
	
	return async ( values ) => {
		try {
			const { createCompany } = await mutateGraphQL<MutationCreateCompanyArgs>( {
				mutation : CreateCompany,
				variables: {
					input: {
						...values.company,
						partner   : sessionCustomerFromPartnerId,
						salesAgent: values.refererAgent?.id,
					},
				},
			} );
			
			await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/emails/welcome`, {
				id: createCompany.id,
			} );
			
			if ( process.env.NODE_ENV === 'production' ) {
				axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/sendSlackNewSignUpNotification`, {
					companyName   : createCompany.name || '-',
					companyEmail  : createCompany.email || '-',
					companyContact: createCompany.contact || '-',
					createdAt     : format( new Date(), 'MMMM dd yyyy' ),
					industry      : values.company.industry || '-',
					referralSource: values.company.referralSource || '-',
					city          : values.location?.address?.city || '-',
					gateway       : 'STRIPE',
					source        : 'WEB Welcome Form',
				} as SlackNewSignUp ).catch( () => null );
			}
			
			await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/onBoardingServersideFunctions`, {
				companyId: createCompany.id,
				location : values.location,
			} );
			
			setCompanyId( createCompany.id );
		} catch ( e ) {
			enqueueSnackbar( 'Sorry! Something went wrong.', { variant: 'error' } );
			console.log( e );
		}
	};
	
}
